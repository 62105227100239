import React, { useEffect, useState } from 'react'
import { useRoutes } from './routes'
import { Header } from './components/Header/Header'
import { ToastContainer } from 'react-toastify'
import ModalContext from './context/ModalContext'
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/ru'
import Modal from './components/Modals/Modal'
import { useLoadUserSettings } from './hooks/useLoadUserSettings'
import { useDispatch } from 'react-redux'
import DataPreloader from './components/DataPreloader'
import { loadYandexMap } from './components/YandexMap'
import { initializeUserSession } from './store/actions/authActions'
import { useAuth } from './hooks/useAuth'
import { setCompany } from './store/actions/dataActions'
import { SocketProvider } from './context/SocketContext'
import styled, { css } from 'styled-components'
import { useTheme } from './context/ThemeContext'

const AppWrapper = styled.div`
  display: grid;
  grid-template-rows: 84px 1fr;
  min-height: 100vh;
  overflow: hidden;
  html[data-theme='dark'] & {
    background: linear-gradient(to bottom, var(--surface-color-lighten), var(--background-color));
  }
`

const Content = styled.div`
  ${({ $isAuthenticated }) =>
    $isAuthenticated &&
    css`
  margin-top: 84px;
`
  }
`

export default function App() {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth()
  const routes = useRoutes()
  const { theme } = useTheme()

  useLoadUserSettings()

  useEffect(() => {
    // Update the `data-theme` attribute whenever the theme changes
    document.documentElement.setAttribute('data-theme', theme)
  }, [theme])

  useEffect(() => {
    const requestNotificationPermission = async () => {
      const permission = await Notification.requestPermission()
      console.log(`Notification permission: ${permission}`)
    }

    // Call the function to request permission
    requestNotificationPermission()
  }, [])

  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [modalPadding, setModalPadding] = useState({ smallScreen: '25px', largeScreen: '25px' })

  const showModal = () => {
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }

  useEffect(() => {
    dispatch(initializeUserSession())
    loadYandexMap()
  }, [dispatch])

  useEffect(() => {
    const lastSelectedCompany = localStorage.getItem('last-selected-company')
    if (lastSelectedCompany) {
      dispatch(setCompany(lastSelectedCompany))
    }
  }, [dispatch])

  return (
    <ModalContext.Provider
      value={{ showModal, closeModal, modalContent, setModalContent, modalPadding, setModalPadding }}
    >
      <SocketProvider>
        <AppWrapper>
          {isAuthenticated &&
            <>
              <Header />
              <DataPreloader />
            </>}
          <Content $isAuthenticated={isAuthenticated}>
            {routes}
          </Content>
        </AppWrapper>
        <Modal modal={modal} setModal={setModal}>
          {modalContent}
        </Modal>
        <ToastContainer />
      </SocketProvider>
    </ModalContext.Provider>
  )
}
